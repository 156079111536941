import React, { useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { graphql, useStaticQuery } from 'gatsby';
import Minska from '../../assets/images/investments/thumbnails/minska.jpg';
import Wilenska from '../../assets/images/investments/thumbnails/wilenska.jpg';
import JanaKazimierza from '../../assets/images/investments/thumbnails/jana-kazimierza.jpg';
import FortWola from '../../assets/images/investments/thumbnails/fort-wola.jpg';
import MieszkanieNaWoli from '../../assets/images/investments/thumbnails/mieszkanie-na-woli.jpg';
import MieszkanieZDusza from '../../assets/images/investments/thumbnails/mieszkanie-z-dusza.jpg';
import MieszkanieZPrzestrzenia from '../../assets/images/investments/thumbnails/mieszkanie-z-przestrzenia.jpg';
import MieszkaniePalac from '../../assets/images/investments/thumbnails/mieszkanie-palac-kultury.jpg';
import Text from '../atoms/Text';
import Lightbox from 'yet-another-react-lightbox';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/navigation';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

const Wrapper = styled.section`
  margin: 50px 0;
  /* padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center; */

  .swiper {
    padding-top: 50px;
    padding-bottom: 50px;

    /* @media (min-width: ${({ theme }) => theme.tablet}) {
      padding-left: 5%;
    } */

    .swiper-button-prev,
    .swiper-button-next {
      color: ${({ theme }) => theme.primary};
      width: 10px;
      z-index: 10000;

      &::before {
        content: '';
        position: absolute;
        min-width: 30px;
        width: 5vw;
        max-width: 50px;
        height: 300px;
        background-color: transparent;
      }
    }
  }

  .swiper .swiper-wrapper .swiper-slide {
    width: 75vw;
    height: 300px;
    transition: transform 0.3s linear;
    cursor: pointer;
    overflow: hidden;

    &.swiper-slide-active {
      transform: scale(1.05);

      @media (min-width: ${({ theme }) => theme.tablet}) {
        transform: scale(1.2);
      }
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      width: 520px;
      height: 340px;
    }
  }

  /* @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  } */

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const SlideWrapper = styled.div`
  transition: all 0.6s linear;
  position: relative;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SlideName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const PhotoItem = ({ image, thumb, group }) => (
//   <div style={{ maxWidth: "250px", width: "200px", padding: "5px" }}>
//     <LightgalleryItem group={group} src={image} thumb={thumb}>
//       <img src={image} style={{ width: "100%" }} />
//     </LightgalleryItem>
//   </div>
// );

const Gallery = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [clickedIndex, setClickedIndex] = useState();
  const images = useStaticQuery(query);

  const thumbnails = [
    ['Mińska', Minska],
    ['Wileńska z loftową sypialnią', Wilenska],
    ['Fort Wola', FortWola],
    ['Jana Kazimierza', JanaKazimierza],
    ['Mieszkanie na Woli', MieszkanieNaWoli],
    ['Mieszkanie z duszą', MieszkanieZDusza],
    ['Mieszkanie z przestrzenią biurową', MieszkanieZPrzestrzenia],
    ['Mieszkanie z widokiem na Pałac Kultury', MieszkaniePalac],
  ];

  const handleClick = (e) => {
    setClickedIndex(e);
    if (e === 1) {
      setOpen1(true);
    } else if (e === 2) {
      setOpen2(true);
    } else if (e === 3) {
      setOpen3(true);
    } else if (e === 4) {
      setOpen4(true);
    } else if (e === 5) {
      setOpen5(true);
    } else if (e === 6) {
      setOpen6(true);
    } else if (e === 7) {
      setOpen7(true);
    } else if (e === 8) {
      setOpen8(true);
    }
  };

  const handleClose = (e) => {
    setClickedIndex(undefined);

    if (e === 1) {
      setOpen1(false);
    } else if (e === 2) {
      setOpen2(false);
    } else if (e === 3) {
      setOpen3(false);
    } else if (e === 4) {
      setOpen4(false);
    } else if (e === 5) {
      setOpen5(false);
    } else if (e === 6) {
      setOpen6(false);
    } else if (e === 7) {
      setOpen7(false);
    } else if (e === 8) {
      setOpen8(false);
    }
  };

  return (
    <Wrapper>
      <Swiper
        modules={[Navigation]}
        spaceBetween={20}
        breakpoints={{
          768: {
            spaceBetween: 100,
          },
        }}
        slidesPerView='auto'
        loop={true}
        centeredSlides
        navigation={true}
      >
        {[...thumbnails].map(([name, src], index) => (
          <SwiperSlide onClick={() => handleClick(index + 1)}>
            <SlideWrapper className='slide-wrapper'>
              <img src={src} alt='' />
              <SlideName>
                <Text style={{ textAlign: 'center', color: '#fff' }}>
                  {name}
                </Text>
              </SlideName>
            </SlideWrapper>
          </SwiperSlide>
        ))}
      </Swiper>

      <Lightbox
        open={open1}
        close={() => handleClose(1)}
        slides={images.minska.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
      <Lightbox
        open={open2}
        close={() => handleClose(2)}
        slides={images.wilenska.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
      <Lightbox
        open={open3}
        close={() => handleClose(3)}
        slides={images.fortWola.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
      <Lightbox
        open={open4}
        close={() => handleClose(4)}
        slides={images.janaKazimierza.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
      <Lightbox
        open={open5}
        close={() => handleClose(5)}
        slides={images.mieszkanieNaWoli.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
      <Lightbox
        open={open6}
        close={() => handleClose(6)}
        slides={images.mieszkanieZDusza.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
      <Lightbox
        open={open7}
        close={() => handleClose(7)}
        slides={images.mieszkanieZPrzestrzenia.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />

      <Lightbox
        open={open8}
        close={() => handleClose(8)}
        slides={images.mieszkaniePalac.edges.map((item, key) => ({
          src: item.node.publicURL,
        }))}
        plugins={[Slideshow, Thumbnails, Zoom]}
        thumbnails={{
          border: '0',
        }}
      />
    </Wrapper>
  );
};

const query = graphql`
  query GalleryImages {
    fortWola: allFile(
      filter: { relativeDirectory: { eq: "investments/fort-wola" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    janaKazimierza: allFile(
      filter: { relativeDirectory: { eq: "investments/jana-kazimierza" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    minska: allFile(
      filter: { relativeDirectory: { eq: "investments/minska" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    wilenska: allFile(
      filter: { relativeDirectory: { eq: "investments/wilenska" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    mieszkanieNaWoli: allFile(
      filter: { relativeDirectory: { eq: "investments/osiedle-na-woli" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    mieszkanieZDusza: allFile(
      filter: { relativeDirectory: { eq: "investments/mieszkanie-z-dusza" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    mieszkanieZPrzestrzenia: allFile(
      filter: {
        relativeDirectory: { eq: "investments/mieszkanie-z-przestrzenia" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    mieszkaniePalac: allFile(
      filter: {
        relativeDirectory: { eq: "investments/mieszkanie-palac-kultury" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

export default Gallery;
