import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import SubpageHeader from '../../components/organisms/SubpageHeader';
import styled from 'styled-components';
import Headline from '../../components/atoms/Headline';
import Text from '../../components/atoms/Text';
import Icon from '../../components/atoms/Icon';
import ZmianaAranzacji from '../../assets/svg/zmiana-aranzacji.svg';
import WizualizacjaPomieszczenia from '../../assets/svg/wizualizacja-pomieszczenia.svg';
import MebleNaWymiar from '../../assets/svg/meble-na-wymiar.svg';
import MebleRuchome from '../../assets/svg/meble-ruchome.svg';
import Oswietlenie from '../../assets/svg/inteligentne-oswietlenie.svg';
import Materialy from '../../assets/svg/materialy.svg';
import Rozwiazania from '../../assets/svg/rozwiazania.svg';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import Gallery from '../../components/organisms/Gallery';
import PromoSection from '../../components/atoms/PromoSection';
import ContactButton from '../../components/atoms/ContactButton';

const Iconography = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Jumbotron = styled.div`
  margin: 100px 0;
  background-color: #000;
  padding: 30px 0;
`;

const JumbotronContainer = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const ButtonWrapper = styled(Link)`
  /* width: 233px; */
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  color: #000;
  font-size: ${({ theme }) => theme.size16};
  font-weight: ${({ theme }) => theme.semiBold};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BigText = styled.div`
  margin: 100px auto 20px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const HeadlineWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  margin-bottom: 100px;
`;

const HeadlineSlide = styled(motion.h5)`
  font-size: ${({ theme }) => theme.size24};
  font-weight: ${({ theme }) => theme.medium};
  color: #000;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    font-size: ${({ theme }) => theme.size28};
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size40};
  }
`;

const PromoSectionContent = styled.div`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const List = styled.ul`
  padding-left: 15px;
`;

const ListItem = styled.li``;

const StyledHeadline = styled(Headline)`
  font-size: ${({ theme }) => theme.size20};
`;

const UnderGallerySection = styled.div`
  margin: 50px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const UnderGalleryText = styled.p`
  font-size: ${({ theme }) => theme.size18};
  font-weight: ${({ theme }) => theme.light};
`;

const ContactTextInfo = styled.p`
  font-size: ${({ theme }) => theme.size15};
  font-weight: ${({ theme }) => theme.medium};
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size16};
  }
`;

const HousePage = () => {
  const icons = [
    ['Zmiana aranżacji pomieszczeń', ZmianaAranzacji],
    ['Wizualizacja każdego pomieszczania', WizualizacjaPomieszczenia],
    ['Meble na wymiar w każdym pomieszczeniu', MebleNaWymiar],
    ['Meble ruchome i składane', MebleRuchome],
    ['Inteligentne i ruchome oświetlenie', Oswietlenie],
    ['Nieograniczony pakiet wyboru materiałów', Materialy],
    ['Innowacyjne rozwiązania systemowe', Rozwiazania],
  ];
  const cms = useStaticQuery(query);
  return (
    <MainTemplate
      isMainPage={false}
      title='Mieszkanie wykończone pod klucz'
      metaDescription='Mieszkanie to Twoje niepowtarzalne i wyjątkowe wnętrze. Z myślą o takich osobach postał program
    „Twój indywidualny projekt”.'
    >
      <SubpageHeader
        headline='Mieszkanie wykończone pod klucz'
        bgImage='isHouse'
      />
      <Iconography id='iconography-trigger'>
        <Headline
          data-aos='headline-fadeup'
          data-aos-anchor='#iconography-trigger'
          style={{ textAlign: 'center', marginBottom: '30px' }}
        >
          {cms.datoCmsApartment.firstSectionHeadline}
        </Headline>

        <Text
          style={{ marginBottom: '70px' }}
          data-aos='headline-fadeup'
          data-aos-anchor='#iconography-trigger'
          data-aos-delay='200'
          dangerouslySetInnerHTML={{
            __html: cms.datoCmsApartment.firstSectionText,
          }}
        />
        <IconsContainer id='icons-trigger'>
          {[...icons].map(([name, src], index) => (
            <Icon Image={src} Text={name} delay={`${index}00`} />
          ))}
        </IconsContainer>
      </Iconography>
      <PromoSection />
      {/* <Jumbotron>
        <JumbotronContainer>
          <StyledHeadline
            data-aos='headline-fadeup'
            style={{ color: '#fff', textAlign: 'center', marginBottom: '50px' }}
          >
            {cms.datoCmsApartment.secondSectionHeadline}
          </StyledHeadline>
          <Text
            style={{ color: '#fff', marginBottom: '20px' }}
            dangerouslySetInnerHTML={{
              __html: cms.datoCmsApartment.thirdSectionOfferHeadline,
            }}
          />
          <Text
            style={{ color: '#fff', marginBottom: '20px' }}
            dangerouslySetInnerHTML={{
              __html: cms.datoCmsApartment.thirdSectionOfferText,
            }}
          />

          <ButtonWrapper to='/kontakt'>
            Kontakt w sprawie przedstawienia oferty
          </ButtonWrapper>
        </JumbotronContainer>
      </Jumbotron> */}
      <BigText>
        <Headline data-aos='headline-fadeup' style={{ textAlign: 'center' }}>
          {/* {cms.datoCmsApartment.thirdSectionHeadline} */}
          Zobacz nasze realizacje
        </Headline>
      </BigText>
      <Gallery />
      <UnderGallerySection>
        <UnderGalleryText>
          Zapytaj o <strong>"Mieszkanie pokazowe"</strong> i umów wizytę w
          jednym z nich
        </UnderGalleryText>
        <ContactTextInfo>
          Skontaktuj się z nami, a odpowiemy na wszystkie Twoje pytania
        </ContactTextInfo>
        <ContactButton />
      </UnderGallerySection>
    </MainTemplate>
  );
};

const query = graphql`
  query ApartmentsCMS {
    datoCmsApartment {
      firstSectionHeadline
      firstSectionText
      secondSectionHeadline
      thirdSectionHeadline
      thirdSectionOfferHeadline
      thirdSectionOfferText
      title
    }
  }
`;

export default HousePage;
