import React from 'react';
import styled from 'styled-components';
import Text from './Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 140px;
  margin: 30px 0;

  img {
    width: 70px;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    margin: 30px 10px;
  }
`;

const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.size14};
  text-align: center;
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.desktop}) {
    font-size: ${({ theme }) => theme.size15};
  }
`;

const Icon = ({ Image, Text, delay }) => {
  return (
    <Wrapper
      data-aos='headline-fadeup'
      data-aos-delay={delay}
      data-aos-anchor='#icons-trigger'
    >
      <img src={Image} />
      <StyledText>{Text}</StyledText>
    </Wrapper>
  );
};

export default Icon;
