import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import ContactButton from './ContactButton';
import Headline from './Headline';

const Section = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* align-items: center; */
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 50%;
  }
`;

const RightContainer = styled.div`
  .gatsby-image-wrapper {
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 45%;
  }
`;

const ArrowsContainer = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ArrowBox = styled.div`
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 10px;
  font-size: ${({ theme }) => theme.size12};
  position: relative;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 25%;
    &:nth-of-type(1) {
      &::before {
        content: url(${arrow1});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(100%, -30%) scale(0.5);
      }
    }
    &:nth-of-type(2) {
      &::before {
        content: url(${arrow2});
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(140%, -10%) scale(0.5);
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    &:nth-of-type(1) {
      &::before {
        transform: translate(80%, -30%) scale(0.4);
      }
    }
    &:nth-of-type(2) {
      &::before {
        transform: translate(120%, -10%) scale(0.5);
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    &:nth-of-type(1) {
      &::before {
        transform: translate(90%, -30%) scale(0.5);
      }
    }
    &:nth-of-type(2) {
      &::before {
        transform: translate(120%, -20%) scale(0.6);
      }
    }
  }
`;

const FeaturesContainer = styled.div`
  margin-bottom: 30px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;

  p {
    font-size: ${({ theme }) => theme.size14};
    font-weight: ${({ theme }) => theme.light};

    @media (min-width: ${({ theme }) => theme.desktop}) {
      font-size: ${({ theme }) => theme.size15};
    }
  }

  @media (max-width: ${({ theme }) => theme.laptop}) {
    &:last-child {
      margin-bottom: 30px;
    }
  }
`;

const Icon = styled.div`
  width: 38px;
  min-width: 38px;
  height: 38px;
  background-color: ${({ theme }) => theme.yellow};
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHeadline = styled(Headline)`
  line-height: unset;
  span {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: ${({ theme }) => theme.yellow};
      z-index: -1;
    }
  }
`;

const PromoSection = () => {
  const img = useStaticQuery(query);
  const [width, setWidth] = useState();

  useEffect(() => {
    if (typeof window === 'undefined') return;
    setWidth(window.innerWidth);

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Section>
      <LeftContainer>
        {/* <StyledHeadline style={{ marginBottom: '30px' }}>
          Oferujemy wykończenie mieszkania pod klucz od <span>2200</span>{' '}
          złotych za m2
        </StyledHeadline> */}
        <StyledHeadline style={{ marginBottom: '30px' }}>
          Pakiet Vip kompleksowego wykończenia mieszkania wraz z zabudowami
          meblowymi {width >= 1400 && <br />} od <span>1780</span> złotych za m
          <sup>2</sup>
        </StyledHeadline>
        <ArrowsContainer>
          <ArrowBox>
            Projekt mieszkania <br />+ dobór materiałów
          </ArrowBox>
          <ArrowBox>Prace remontowo-wykończeniowe</ArrowBox>
          <ArrowBox>Meble na wymiar + oświetlenie</ArrowBox>
        </ArrowsContainer>
        <FeaturesContainer>
          <Feature>
            <Icon>
              <svg
                width='11'
                height='19'
                viewBox='0 0 11 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.381104 16.6288L1.9902 18.2379L10.9902 9.23792L1.9902 0.237915L0.381104 1.84701L7.77201 9.23792L0.381104 16.6288Z'
                  fill='white'
                />
              </svg>
            </Icon>
            <p>Gratis - odbiór lokalu przez naszego inżyniera</p>
          </Feature>
          <Feature>
            <Icon>
              <svg
                width='11'
                height='19'
                viewBox='0 0 11 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.381104 16.6288L1.9902 18.2379L10.9902 9.23792L1.9902 0.237915L0.381104 1.84701L7.77201 9.23792L0.381104 16.6288Z'
                  fill='white'
                />
              </svg>
            </Icon>
            <p>Gratis - analiza dokumentów przeniesienia własności</p>
          </Feature>
        </FeaturesContainer>
        <ContactButton />
      </LeftContainer>
      <RightContainer>
        <GatsbyImage
          image={img.recievekeys.childImageSharp.gatsbyImageData}
          alt='Para odbiera klucze do mieszkania'
        />
      </RightContainer>
    </Section>
  );
};

const query = graphql`
  query PromoSectionQuery {
    recievekeys: file(name: { eq: "recievekeys" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE, quality: 100)
      }
    }
  }
`;

export default PromoSection;
